<template>
  <div v-if="text && showCookieBanner" class="cookie-banner">
    <div class="is-wide container flex items-center gap-16 py-24">
      <div class="ck-content flex-1" v-html="text" />
      <div>
        <button
          class="button is-primary"
          @click.prevent="showCookieBanner = false"
        >
          {{ $texts('close', 'Close') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLocalStorage } from '@vueuse/core'

const showCookieBanner = useLocalStorage<boolean>('show_cookie_banner', true)

const { data: text } = await useLazyAsyncData(() =>
  useGraphqlQuery('cookieBanner', null, {
    graphqlCaching: {
      client: true,
    },
  }).then((v) => v.data.siteConfig?.paragraph?.body),
)
</script>

<style lang="postcss">
.cookie-banner {
  @apply sticky bottom-0 left-0 z-[99999] w-full bg-white shadow-elevated;
  .section {
    @apply py-15;
  }
}

.cookie-banner-inner {
  .button {
    @apply mobile-only:mt-15 mobile-only:w-full;
  }
  @screen md {
    @apply flex items-center justify-between;
  }
}
</style>
