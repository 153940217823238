<template>
  <div>
    <ClientOnly>
      <AdminBar v-if="accessToolbar && !isEditing" />
    </ClientOnly>

    <div class="relative">
      <div class="bg-hero">
        <NuxtPageDependency>
          <SiteHeader />
          <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
        </NuxtPageDependency>
      </div>

      <div>
        <ClientOnly>
          <DrupalMessages v-if="!isEditing" />
        </ClientOnly>
        <div>
          <slot />
        </div>
      </div>
    </div>
    <PageFooter />
    <ClientOnly>
      <CookieBanner />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
const { accessToolbar } = await useDrupalUser()
const breadcrumb = useBreadcrumbLinks()

const isEditing = computed(
  () => !!(route.query.blokkliEditing || route.query.blokkliPreview),
)

const showBreadcrumb = computed(() => route.meta.hideBreadcrumb !== true)
</script>
