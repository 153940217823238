<template>
  <div
    v-if="messages.length"
    class="fixed left-0 top-0 z-[9999999] w-full p-24 grid gap-24"
  >
    <button
      v-for="(message, i) in messages"
      :key="i"
      class="font-bold flex items-center justify-between rounded p-24 w-full shadow-lg md:text-lg group border"
      :class="classes[message.type]"
      @click.prevent="removeMessage(i)"
    >
      <div v-html="message.message" />
      <div
        class="p-4 bg-white/15 rounded-full group-hover:bg-white/70 group-hover:text-gray-800"
      >
        <SpriteSymbol name="close" class="size-24 fill-current" />
      </div>
    </button>
  </div>
</template>

<script lang="ts" setup>
import type { DrupalMessageType } from '~/composables/useDrupalMessages'

const { messages, removeMessage } = useDrupalMessages()

const classes: Record<DrupalMessageType, string> = {
  error: 'text-white bg-red-500 border-red-200',
  warning: 'text-blue-100 bg-blue-800 border-blue-300',
  status: 'text-white bg-lime-600 border-lime-300',
}
</script>
