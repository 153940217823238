import { useWindowSize } from '@vueuse/core'

export default function () {
  const { width } = useWindowSize()
  const isMobile = computed(() => {
    return import.meta.server || width.value < 1024
  })

  return { isMobile }
}
