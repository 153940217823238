<template>
  <div
    v-if="employer && pensionFund"
    v-click-away="close"
    class="site-header-client-state relative"
  >
    <div class="dropdown-trigger flex">
      <NuxtLink
        :to="employerUrl"
        class="button rounded-r-none px-8 py-4 font-condensed font-regular lg:py-8"
      >
        <div class="client-state-button-text">
          <div class="font-bold">{{ employer.title }}</div>
          <div>{{ pensionFund.title }}</div>
        </div>
      </NuxtLink>
      <button
        class="button !w-36 items-center justify-center rounded-l-none px-0 py-4"
        @click="selectorOpen = !selectorOpen"
      >
        <div class="icon" :class="{ 'is-active': selectorOpen }">
          <SpriteSymbol name="nav-arrow-down" class="!m-0" />
        </div>
      </button>
    </div>
    <transition name="dropdown">
      <div v-if="selectorOpen" class="dropdown-menu">
        <div class="dropdown-content">
          <div class="dropdown-item has-text-weight-bold">
            {{ $texts('myPensionFund', 'My pension fund') }}
          </div>
          <DrupalLink
            v-for="(link, index) in employerLinks"
            :key="index"
            :to="link.to"
            class="dropdown-item"
            >{{ link.title }}</DrupalLink
          >
          <NuxtLink :to="employerUrl" class="dropdown-item">{{
            $texts('myContactPerson', 'My contact persons')
          }}</NuxtLink>

          <hr class="dropdown-divider" />
          <NuxtLink :to="changeLink" class="dropdown-item">{{
            $texts('changeEmployer', 'Change employer')
          }}</NuxtLink>
          <template v-if="accessToolbar">
            <hr class="dropdown-divider" />
            <div class="dropdown-item">
              <button class="button is-danger" @click="setEmployerId()">
                Kein Arbeitgeber wählen
              </button>
              <p class="help">Nur für Redaktion sichtbar</p>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
const { accessToolbar } = await useDrupalUser()
const { employerUrl, setEmployerId, employer, pensionFund, employerLinks } =
  useVisitorState()
const { $texts } = useNuxtApp()

const selectorOpen = ref(false)
const changeLink = computed(() => {
  return {
    name: 'choose-employer',
  }
})

const route = useRoute()

watch(route, () => (selectorOpen.value = false))

function close() {
  selectorOpen.value = false
}
</script>

<style lang="postcss">
.site-header-client-state {
  @apply ml-auto;
  min-width: 0;

  .dropdown-trigger {
    width: 100%;
    min-width: 0;
    .control:first-child {
      min-width: 0;
      .button {
        text-align: left;
        justify-content: space-between;
        font-weight: normal;
        padding-left: 0.5rem;
        width: 100%;
        @screen md {
          min-width: 13rem;
          max-width: calc(100vw - 35rem);
        }
        @screen lg {
          max-width: 24rem;
        }
      }
    }
    .button {
      min-width: 0;
      @apply border-blue-200 bg-blue-100 text-blue-600;
      &:hover {
        @apply border-blue-300 bg-blue-200 text-blue-900;
      }
      html.has-highlighted-hero.has-highlight-primary & {
        @apply border-blue-700 bg-blue-800 text-white;
        &:hover {
          @apply border-blue-500 bg-blue-700;
        }
      }
      html.has-highlighted-hero.has-highlight-secondary & {
        @apply border-green-500 bg-green-700 text-white;
        &:hover {
          @apply border-green-400 bg-green-800;
        }
      }
    }
  }
  .dropdown-menu {
    @apply absolute left-0 top-full w-full bg-white shadow;
    .dropdown-item {
      @apply flex px-12 py-8;
    }
    a.dropdown-item {
      @apply hover:bg-gray-50;
    }
    @media (max-width: theme('screens.md')) {
      left: initial;
      right: 0;
      width: calc(100vw - var(--section-gap-h) * 2);
      max-width: 30rem;
    }
  }
  .button .icon.is-active {
    transform: rotate(180deg);
  }
}
.client-state-button-text {
  @apply text-sm;
  line-height: 1.1;
  min-width: 0;
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
