<template>
  <header class="site-header">
    <div class="site-header-inner is-wide container">
      <div class="site-header-inner-main">
        <nuxt-link :to="{ name: 'home' }" class="site-header-logo is-block">
          <SpriteSymbol name="logo" width="165" height="43" />
        </nuxt-link>
        <client-only>
          <SiteHeaderClientState />
        </client-only>
      </div>
      <transition :name="isMobile ? 'mobile-menu' : ''">
        <div v-show="showAside" class="site-header-inner-aside">
          <div class="site-header-languages">
            <language-switcher />
          </div>
          <div class="site-header-cta p-20 md:p-0">
            <nuxt-link
              v-if="myPublicaLink"
              :to="myPublicaLink.url?.path"
              class="button is-primary w-full"
            >
              <div class="icon">
                <SpriteSymbol name="lock" />
              </div>
              <span>{{ myPublicaLink.title }}</span>
            </nuxt-link>
          </div>
          <div class="site-header-navigation">
            <navigation v-model="activeFirstLevel" />
          </div>
        </div>
      </transition>
    </div>
    <div
      class="site-header-burger"
      :class="{ 'is-active': mobileMenuOpen, 'is-offset': burgerIsOffset }"
    >
      <button
        class="button is-bubble flex !h-50 !w-50 items-center justify-center !p-15"
        @click="mobileMenuOpen = !mobileMenuOpen"
      >
        <SpriteSymbol name="menu" inline class="!m-0" />
      </button>
    </div>
    <div
      class="site-header-mobile-background md:hidden"
      :class="{ 'is-active': mobileMenuOpen }"
    />
    <transition name="site-header-overlay">
      <div
        v-if="!isMobile && activeFirstLevel"
        class="site-header-overlay"
        @click="activeFirstLevel = ''"
      />
    </transition>
  </header>
</template>

<script lang="ts" setup>
const data = await useInitData()
const { isMobile } = useViewport()

const activeFirstLevel = ref('')
const mobileMenuOpen = ref(false)

const showAside = computed(() => {
  return isMobile.value ? mobileMenuOpen.value : true
})

const myPublicaLink = computed(() => data.value.siteConfig.pageMypublica)

const route = useRoute()

const burgerIsOffset = ref(false)

function isSamsungBrowser(): boolean {
  return !!navigator.userAgent.match(
    /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
  )?.length
}

watch(
  () => route.fullPath,
  () => {
    if (isMobile.value) {
      mobileMenuOpen.value = false
    } else {
      activeFirstLevel.value = ''
    }
  },
)

onMounted(() => {
  burgerIsOffset.value = isSamsungBrowser()
})
</script>

<style lang="postcss">
.site-header {
  z-index: 2000;

  @screen md {
    @apply px-25 pt-40;
  }
}
.site-header-inner {
  position: relative;
  @screen md {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.site-header-inner-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--site-header-height);
  border-bottom: 1px solid rgba(black, 0.1);
  .dropdown-menu {
    z-index: 7000;
  }
  html.has-highlighted-hero & {
    --outline-color: white;
  }
  @screen md {
    justify-content: flex-start;
    position: absolute;
    top: 0;
    height: auto;
    padding: 0;
    border-bottom: 0;
    @apply left-[10px];
  }
  @apply lg:left-[14px];
}
.site-header-cta {
  html.has-highlighted-hero & {
    --outline-color: white;
  }
}

.site-header-inner-aside {
  @media (max-width: theme('screens.md')) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4000;
    padding-top: 1.5rem;
    overflow: auto;
  }
  @apply mobile-only:fixed mobile-only:left-0
  mobile-only:top-0
  mobile-only:z-[4000]
  mobile-only:h-full
  mobile-only:w-full
  mobile-only:overflow-auto
  mobile-only:pt-25;

  @screen md {
    /* The element can contain a display: none style attribute due to mobile */
    /* visibility logic. Here we make sure this element is always visible for */
    /* tablet viewports and upwards. */
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
}

.site-header-logo,
.site-header-pension-fund,
.site-header-languages,
.site-header-navigation,
.site-header-cta {
  @screen md {
    position: relative;
    z-index: 4000;
  }
}

.site-header-cta {
  @media (max-width: theme('screens.md')) {
    padding: 1.5rem;
    .button {
      width: 100%;
      background: white;
      @apply text-blue-700;
    }
  }
  @screen md {
    .button {
      html.has-highlighted-hero.has-highlight-secondary & {
        @apply bg-green-500;
        color: white;
        &:hover {
          @apply bg-green-700;
        }
      }
    }
  }
}

.site-header-logo {
  height: 2rem;
  width: auto;
  @apply mr-24;
  svg {
    width: auto;
    height: 100%;
    display: block;
    @apply text-blue-400;
    html.has-highlighted-hero & {
      @apply text-white;
    }
  }
  @screen md {
    @apply rounded;
    height: 3rem;
    outline-offset: 3px;
    &:hover {
      svg path {
        @apply fill-blue-500;
      }
    }
    svg {
      /* Manual values for visual alignment that looks good. */
      height: 43px;
      margin-top: 3px;
    }
  }
}

.site-header-languages {
  margin-left: auto;
  margin-right: 0.25rem;
  li {
    &:not(:first-child) {
      @screen md {
        margin-left: -0.5rem;
      }
    }
  }

  @media (max-width: theme('screens.md')) {
    padding: 0 1.5rem;
    margin-right: 0;
    .language-switcher {
      justify-content: space-between;
      a,
      div {
        color: white;
      }
    }
  }
  html.has-highlighted-hero & {
    --outline-color: white;
    a {
      color: white;
    }
  }
}

.site-header-navigation {
  @screen md {
    @apply mt-40;
    flex: 0 0 100%;
    z-index: 5000;
  }
}

.site-header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @apply bg-gray-900/30;
  z-index: 4990;
}

.site-header-overlay-enter-active,
.site-header-overlay-leave-active {
  transition: 0.5s;
}

.site-header-overlay-enter-from,
.site-header-overlay-leave-to {
  opacity: 0;
}

.mobile-menu-enter-active {
  transition: 2.4s;
  .site-header-languages,
  .site-header-cta,
  .navigation-first-level {
    transition: 0.5s;
  }
  .site-header-languages {
    transition-delay: 0.25s;
  }
  .site-header-cta {
    transition-delay: 0.35s;
  }
  .navigation-first-level:nth-child(1) {
    transition-delay: 0.4s;
  }
  .navigation-first-level:nth-child(2) {
    transition-delay: 0.5s;
  }
  .navigation-first-level:nth-child(3) {
    transition-delay: 0.6s;
  }
  .navigation-first-level:nth-child(4) {
    transition-delay: 0.7s;
  }
  .navigation-first-level:nth-child(5) {
    transition-delay: 0.8s;
  }
  .navigation-first-level:nth-child(6) {
    transition-delay: 0.9s;
  }
}

.mobile-menu-leave-active {
  transition: 0.8s;
}

.mobile-menu-leave-to {
  transform: translateY(6rem);
  opacity: 0;
}

.mobile-menu-enter-from {
  transform: translateY(1px);
  .site-header-languages,
  .site-header-cta,
  .navigation-first-level {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.site-header-burger {
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5000;
  @apply text-blue-500;

  &.is-active {
    button {
      @apply bg-blue-900;
      color: white;
      transition-delay: 0s;
      @apply border-blue-700;
    }
    #burger-line-1,
    #burger-line-4 {
      opacity: 0;
    }
    #burger-line-2 {
      transform: rotate(45deg);
    }
    #burger-line-3 {
      transform: rotate(-45deg);
    }
  }
  &.is-offset {
    @apply transform-none left-auto right-24;
  }
  button {
    border-color: white !important;
    transition: 0.5s;
    outline: none !important;
    transition-delay: 0.3s;
    @apply shadow;

    &.is-bubble {
      height: 65px;
      width: 65px;
    }
  }

  svg {
    rect {
      fill: currentColor;
      transition: 0.4s;
      transform-origin: center;
    }
  }
  @apply md:hidden;
}

.site-header-mobile-background {
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3000;
  width: 3rem;
  height: 3rem;
  &.is-active {
    &:before {
      @apply bg-blue-700;
      transform: translate(-50%, -50%);
      transition-delay: 0s;
    }
  }
  &:before {
    content: '';
    position: absolute;
    @apply bg-blue-600;
    width: calc(200vmax + 5rem);
    height: calc(200vmax + 5rem);
    border-radius: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.5s cubic-bezier(0.68, 0.03, 0.39, 1.15);
    transition-delay: 0.3s;
    z-index: -1;
  }
}
</style>
