<template>
  <nav
    id="main-navigation"
    tabindex="-1"
    class="navigation"
    :class="{ 'is-active': firstLevelIsOpen }"
    @keydown.left="goToLeft"
    @keydown.right="goToRight"
  >
    <ul ref="list" class="navigation-list">
      <component
        :is="
          isSearch(first) ? NavigationFirstLevelSearch : NavigationFirstLevel
        "
        v-for="(first, i) in links"
        :key="'first_' + i"
        v-bind="first"
        ref="linkElements"
        :is-open="modelValue === first.link.label"
        :has-transition="hasTransition || isMobile"
        @toggle="onToggle(first.link.label)"
        @hover="onHover(first.link.label)"
        @open="setActiveFirstLevel(first.link.label)"
        @close="setActiveFirstLevel"
      />
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {
  StaticMenuLink,
  type MainMenuLinkTreeFirstFragment,
} from '#graphql-operations'
import { NavigationFirstLevel, NavigationFirstLevelSearch } from '#components'

const linkElements = ref<HTMLDivElement[]>([])

const data = await useInitData()

const links = computed(() => data.value.mainMenuLinks)

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits(['update:modelValue'])

const hasTransition = ref(true)

const { isMobile } = useViewport()

const firstLevelIsOpen = computed(() => !!props.modelValue)

const currentIndex = computed<number>(() => {
  return links.value?.findIndex((v) => v.link.label === props.modelValue) || -1
})

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    hasTransition.value = (newValue && !oldValue) || !newValue
  },
)

function openFirstLevel(index: number) {
  if (!links.value) {
    return
  }
  const targetIndex =
    index < 0 ? links.value.length - 1 : index % links.value.length

  // @ts-ignore
  const target = linkElements.value[targetIndex]
  if (!target) {
    return
  }

  const link = target.querySelector('.navigation-first-level-button')
  if (link && link instanceof HTMLElement) {
    link.focus()
  }
}

function goToLeft() {
  openFirstLevel(currentIndex.value - 1)
}
function goToRight() {
  openFirstLevel(currentIndex.value + 1)
}
function isSearch(item: MainMenuLinkTreeFirstFragment) {
  return item.link.staticMenuLink === StaticMenuLink.SEARCH
}

function setActiveFirstLevel(label = '') {
  emit('update:modelValue', label)
}

function onToggle(label: string) {
  if (props.modelValue === label) {
    setActiveFirstLevel()
    return
  }

  setActiveFirstLevel(label)
}
function onHover(label: string) {
  setActiveFirstLevel(label)
}
</script>

<style lang="postcss">
.navigation {
  @screen md {
    background: white;
    transition: 0.4s;

    box-shadow: 0 6px 20px 0 rgb(0 0 0 / 8%);

    height: calc(var(--navigation-height) + 2px);
    @apply rounded-large;

    &.is-active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      @apply bg-gray-100;
    }
  }
}

.navigation-list {
  padding-bottom: 5rem;
  @screen md {
    padding-bottom: 0;
    display: flex;
    position: relative;
    z-index: 5000;
    justify-content: space-between;
  }
  @screen lg {
    justify-content: flex-start;
  }
}
</style>
