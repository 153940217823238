<template>
  <ul class="language-switcher flex">
    <li v-for="item in linkItems" :key="item.code">
      <a
        v-if="item.href"
        :href="item.href"
        :title="item.languageLabel"
        :class="{ 'font-bold': item.active, 'text-white': isWhite }"
        >{{ item.code }}</a
      >
      <button v-else class="" disabled :title="getDisabledTitle(item.code)">
        {{ item.code }}
      </button>
    </li>
  </ul>
</template>

<script lang="ts" setup>
const MISSING_TEXT: Record<string, string> = {
  fr: "Cette page n'est pas disponible en français.",
  it: 'Questa pagina non è disponibile in italiano.',
  de: 'Diese Seite ist nicht auf Deutsch verfügbar.',
  en: 'This page is not available in English.',
}

const LANGUAGE_LABEL: Record<string, string> = {
  de: 'Deutsch',
  fr: 'Français',
  it: 'Italiano',
  en: 'English',
}

defineProps<{
  isWhite?: boolean
}>()

const language = useCurrentLanguage()
const links = useLanguageLinks()

const linkItems = computed(() => {
  return Object.keys(LANGUAGE_LABEL).map((code) => {
    const matchingLink = links.value.find((v: any) => v.code === code)
    return {
      href: matchingLink?.to,
      code,
      languageLabel: LANGUAGE_LABEL[code],
      active: code === language.value,
    }
  })
})

function getDisabledTitle(code: string): string {
  return MISSING_TEXT[code]
}
</script>

<style lang="postcss">
.language-switcher {
  li {
    > a,
    > button {
      @apply block p-16 uppercase;
    }
  }
}
</style>
