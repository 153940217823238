<template>
  <li class="navigation-second-level has-third-level-links is-my-pension-fund">
    <template v-if="hasSelectedEmployer">
      <nuxt-link :to="pensionFund?.url?.path">{{
        $texts('myPensionFund', 'My pension fund')
      }}</nuxt-link>
      <ul>
        <li v-for="link in employerLinks" :key="link.title">
          <nuxt-link :to="link.to">{{ link.title }}</nuxt-link>
        </li>
      </ul>
      <NuxtLink
        :to="employerUrl"
        class="button is-primary has-text-weight-bold mt-4"
        >{{ $texts('myContactPerson', 'My contact persons') }}</NuxtLink
      >
    </template>
    <template v-else>
      <div>
        {{
          $texts(
            'chooseEmployerLead',
            'Select your employer so that we can show you suitable information and contact persons.',
          )
        }}
        <div>
          <nuxt-link
            :to="{ name: 'choose-employer' }"
            class="button is-primary is-fullwidth mt-4"
            >{{ $texts('chooseEmployer', 'Choose employer') }}</nuxt-link
          >
        </div>
      </div>
    </template>
  </li>
</template>

<script lang="ts" setup>
const { employerUrl, hasSelectedEmployer, pensionFund, employerLinks } =
  useVisitorState()
</script>

<style lang="postcss">
.navigation-second-level.is-my-pension-fund {
  @screen lg {
    flex: 0 0 var(--navigation-content-addon-width);
    @apply border border-blue-100 bg-blue-50;
  }
}
</style>
