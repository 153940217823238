<template>
  <li
    class="navigation-second-level"
    :class="{ 'has-third-level-links': hasThirdLevelLinks }"
  >
    <DrupalLink :to="link.url?.path">{{ link.label }}</DrupalLink>
    <ul v-if="hasThirdLevelLinks">
      <li v-for="(item, i) in links" :key="'first_' + i">
        <DrupalLink :to="item.link?.url?.path">{{
          item.link.label
        }}</DrupalLink>
      </li>
    </ul>
  </li>
</template>

<script lang="ts" setup>
import type { MainMenuLinkTreeSecondFragment } from '#graphql-operations'

const props = defineProps<{
  link: MainMenuLinkTreeSecondFragment['link']
  links: MainMenuLinkTreeSecondFragment['links']
}>()

const hasThirdLevelLinks = computed(() => !!props.links.length)
</script>

<style lang="postcss">
.navigation-second-level {
  > a {
    display: block;
    &:not(:last-child) {
      margin-bottom: 1.25rem;
    }
    color: white;
    @apply font-condensed font-bold;
    @screen md {
      @apply text-body;
    }
  }
  > ul > li > a {
    display: block;
    color: white;
    @screen md {
      @apply text-blue-500;
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      &:hover {
        text-decoration: underline;
        @apply text-blue-800;
      }
    }
    @screen lg {
      @apply text-lg;
    }
  }
  > ul {
    display: grid;
    grid-gap: 0.5rem;
  }
}
.navigation-second-level + .navigation-second-level {
  @media (max-width: theme('screens.md')) {
    margin-top: 1rem;
    padding-top: 1rem;
    @apply border-t border-t-blue-700;
  }
}
</style>
